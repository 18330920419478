import React, { useState, useEffect } from "react";
import { useTrello } from "../contexts/TrelloContext";

import { useStyletron } from "baseui";
import { Button } from "baseui/button";
import { ButtonGroup } from "baseui/button-group";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from "baseui/modal";
import { LabelMedium, LabelXSmall } from "baseui/typography";
import { Select } from "baseui/select";
import { StyledLink } from "baseui/link";

import { useLicense } from "../contexts/LicenseContext";
import { InvalidLicenseBox } from "../components/InvalidLicenseBox";
import { pdf } from "@react-pdf/renderer";
import { Simple } from "../templates/pdf/simple";
import { SimpleComments } from "../templates/pdf/simple-comments";
import { downloadBlob } from "../utils/export";

const TEMPLATES = {
  1: { id: 1, label: "Simple" },
  2: { id: 2, label: "Simple with comments" },
};

const DEFAULTS = {
  template: 1,
};

export default function CardPdfView() {
  const [css, theme] = useStyletron();
  const $trello = useTrello();

  const [isAuthorizationModalOpen, setIsAuthorizationModalOpen] =
    useState(false);

  const [isExporting, setIsExporting] = useState(false);
  const [board, setBoard] = useState();
  const [lists, setLists] = useState();
  const [card, setCard] = useState();

  const [template, setTemplate] = useState(DEFAULTS.template);

  const { valid, expiresAt } = useLicense();

  useEffect(() => {
    async function fetchData() {
      setCard(
        (await $trello.cards("all")).find(
          (card) => card.id === $trello.arg("cardId")
        )
      );
      setBoard(await $trello.board("all"));
      setLists(await $trello.lists("all"));
    }

    if ($trello) {
      fetchData();
    }
  }, [$trello]);

  useEffect(() => {
    if ($trello) {
      async function checkIfAuthorizationIsNeeded() {
        const token = await $trello.getRestApi().getToken();
        if (!token) {
          setIsAuthorizationModalOpen(true);
        }
      }
      checkIfAuthorizationIsNeeded();
    }
  }, [$trello]);

  if (!valid || !expiresAt) {
    return <InvalidLicenseBox />;
  }

  async function exportToPdf() {
    setIsExporting(true);

    let instance;
    if (template === 2) {
      const token = await $trello.getRestApi().getToken();
      const params = new URLSearchParams({
        // key: "6ed16050c4542ff86616d07f3b595b52",
        key: "c780210a62a449d50baf4dfebaac67ba",
        token,
        filter: "commentCard",
      });
      const result = await fetch(
        `https://api.trello.com/1/cards/${card.id}/actions?${params}`
      );

      const comments = await result.json();

      instance = pdf(
        <SimpleComments
          card={card}
          comments={comments}
          board={board}
          list={lists.find((list) => list.id === card.idList)}
        />
      );
    } else {
      instance = pdf(
        <Simple
          card={card}
          board={board}
          list={lists.find((list) => list.id === card.idList)}
        />
      );
    }

    downloadBlob(`${card.name}.pdf`, await instance.toBlob());
    setIsExporting(false);
  }

  return (
    <>
      <div
        className={css({
          paddingLeft: theme.sizing.scale600,
          paddingRight: theme.sizing.scale600,
          paddingBottom: "80px",
          paddingTop: theme.sizing.scale600,
        })}
      >
        <LabelMedium
          $style={{
            marginTop: theme.sizing.scale500,
            marginBottom: theme.sizing.scale100,
          }}
        >
          Template
        </LabelMedium>
        <Select
          options={Object.values(TEMPLATES)}
          size="compact"
          value={[
            {
              id: template,
            },
          ]}
          placeholder="Select a template"
          onChange={(params) =>
            params &&
            params.value &&
            params.value[0] &&
            setTemplate(params.value[0].id)
          }
          overrides={{
            Root: {
              style: {
                marginTop: theme.sizing.scale300,
              },
            },
          }}
          clearable={false}
        />
        <LabelXSmall
          $style={{
            marginTop: theme.sizing.scale300,
            marginBottom: theme.sizing.scale300,
            color: theme.colors.contentSecondary,
            fontWeight: 400,
          }}
        >
          Contact us at{" "}
          <StyledLink target="_blank" href="mailto:support@smart-export.app">
            support@smart-export.app
          </StyledLink>{" "}
          if you need a custom template.
        </LabelXSmall>
        <div
          className={css({
            width: "100%",
            position: "fixed",
            display: "flex",
            flexDirection: "column",
            gap: theme.sizing.scale300,
            boxSizing: "border-box",
            bottom: 0,
            left: 0,
            backgroundColor: theme.colors.background,
            boxShadow: theme.lighting.shadow400,
            paddingTop: theme.sizing.scale400,
            paddingRight: theme.sizing.scale600,
            paddingBottom: theme.sizing.scale400,
            paddingLeft: theme.sizing.scale600,
          })}
        >
          <div
            className={css({
              display: "flex",
              justifyContent: "space-between",
            })}
          >
            <div className={css({ width: "33%" })}>
              {$trello && (
                <Button kind="minimal" onClick={() => $trello.closeModal()}>
                  Cancel
                </Button>
              )}
            </div>
            <div className={css({ width: "33%" })}>
              <ButtonGroup
                overrides={{
                  Root: {
                    style: {
                      justifyContent: "flex-end",
                    },
                  },
                }}
              >
                <Button
                  kind="primary"
                  onClick={() => exportToPdf()}
                  overrides={{
                    BaseButton: {
                      style: {
                        backgroundColor: theme.colors.accent,
                        color: theme.colors.contentInversePrimary,
                        ":hover": {
                          backgroundColor: theme.colors.accent500,
                        },
                      },
                    },
                  }}
                  isLoading={isExporting}
                  disabled={!card}
                >
                  {!card ? "Loading data" : "Export to PDF"}
                </Button>
              </ButtonGroup>
            </div>
          </div>
        </div>
      </div>
      <Modal
        onClose={() => setIsAuthorizationModalOpen(false)}
        isOpen={isAuthorizationModalOpen}
        overrides={{ Close: { style: { display: "none" } } }}
        animate
        closeable={false}
        autoFocus
      >
        <ModalHeader>Authorization needed</ModalHeader>
        <ModalBody>
          We need you to authorize to be able to use this Power-Up.
        </ModalBody>
        <ModalFooter>
          <ModalButton
            onClick={async () => {
              try {
                await $trello.getRestApi().authorize({ scope: "read" });
                setIsAuthorizationModalOpen(false);
              } catch (error) {}
            }}
          >
            Authorize
          </ModalButton>
        </ModalFooter>
      </Modal>
    </>
  );
}
